<template>
    <PlanningContact :showEmail="showEmail" :toggleEmailWindow="toggleEmailWindow" :selectedPlan="selectedPlan" />
    <div class="pricing-container">
        <section class="pricing-panel">
            <div class="header">
                <h1>{{ $t("Streamline your Recruitment. Start free.") }}</h1>
                <h4>{{ $t("Choose the perfect plan for your business needs") }}</h4>
            </div>
            <div class="saving-container">
                <div class="save-span">
                    <span>{{ $t("Save 15%") }}</span> {{ $t("on monthly plan!") }}
                </div>
                <div class="time-container">
                    <button class="month" :class="Plan === 'month' ? 'before:translate-x-[0] text-white' : 'before:translate-x-[100%] text-[#a1a1aa]'" @click="SelectPlan('month')">
                        {{ $t("Monthly") }}
                    </button>
                    <button class="year" :class="Plan === 'year' ? ' text-white' : ' text-[#a1a1aa]'" @click="SelectPlan('year')">{{ $t("Yearly") }}</button>
                </div>
            </div>
            <div class="pricing-cards-wrapper">
                <div class="pricing-card">
                    <div class="pricing-header">
                        <div class="pricing-type">
                            <div class="h2">{{ $t("Free") }}</div>
                            <div class="h5">{{ $t("Best for personal use") }}</div>
                        </div>
                        <div class="price-value"><span>$0</span>{{ $t("/month") }}</div>
                    </div>
                    <button class="pricing-card-button" @click="() => this.$router.push('/register')">{{ $t("Get Started") }}</button>
                    <div class="pricing-features">
                        <div class="h5">{{ $t("What you get:") }}</div>
                        <div class="infos-list">
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none">
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M20.9556 5.90211L9.05063 19.5078L3.04199 13.4991L4.5004 12.0407L8.95017 16.4905L19.4034 4.54395L20.9556 5.90211Z"
                                        fill="#1D4ED8"
                                    />
                                </svg>
                                {{ $t("User") }}
                            </div>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none">
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M20.9556 5.90211L9.05063 19.5078L3.04199 13.4991L4.5004 12.0407L8.95017 16.4905L19.4034 4.54395L20.9556 5.90211Z"
                                        fill="#1D4ED8"
                                    />
                                </svg>
                                {{ $t("5 Tests per Assessment") }}
                            </div>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none">
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M20.9556 5.90211L9.05063 19.5078L3.04199 13.4991L4.5004 12.0407L8.95017 16.4905L19.4034 4.54395L20.9556 5.90211Z"
                                        fill="#1D4ED8"
                                    /></svg
                                >10 {{ $t("Assessments") }}
                            </div>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none">
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M20.9556 5.90211L9.05063 19.5078L3.04199 13.4991L4.5004 12.0407L8.95017 16.4905L19.4034 4.54395L20.9556 5.90211Z"
                                        fill="#1D4ED8"
                                    /></svg
                                >{{ $t("Reporting and analytics") }}
                            </div>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none">
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M20.9556 5.90211L9.05063 19.5078L3.04199 13.4991L4.5004 12.0407L8.95017 16.4905L19.4034 4.54395L20.9556 5.90211Z"
                                        fill="#1D4ED8"
                                    /></svg
                                >{{ $t("What you get") }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pricing-card">
                    <div class="pricing-header">
                        <div class="pricing-type">
                            <div class="h2">{{ $t("Basic") }}</div>
                            <div class="h5">{{ $t("Best for Small Business") }}</div>
                        </div>
                        <div class="price-value">
                            <span>${{ Plan === "year" ? 84 : 99 }}</span
                            >{{ $t("/month") }}
                        </div>
                    </div>
                    <!-- @click="toggleEmailWindow(`Basic - ${Plan === 'year' ? 'yearly' : 'Mounthly'}`)" -->
                    <button class="pricing-card-button" @click="navigateToRegister(`Basic - ${Plan === 'year' ? 'yearly' : 'Mounthly'}`)">{{ $t("Get Started") }}</button>
                    <div class="pricing-features">
                        <div class="h5">{{ $t("Upgraded features:") }}</div>
                        <div class="infos-list">
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none">
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M20.9556 5.90211L9.05063 19.5078L3.04199 13.4991L4.5004 12.0407L8.95017 16.4905L19.4034 4.54395L20.9556 5.90211Z"
                                        fill="#1D4ED8"
                                    />
                                </svg>
                                3 {{ $t("Users") }}
                            </div>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none">
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M20.9556 5.90211L9.05063 19.5078L3.04199 13.4991L4.5004 12.0407L8.95017 16.4905L19.4034 4.54395L20.9556 5.90211Z"
                                        fill="#1D4ED8"
                                    /></svg
                                >{{ $t("5 Tests per Assessment") }}
                            </div>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none">
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M20.9556 5.90211L9.05063 19.5078L3.04199 13.4991L4.5004 12.0407L8.95017 16.4905L19.4034 4.54395L20.9556 5.90211Z"
                                        fill="#1D4ED8"
                                    /></svg
                                >50 {{ $t("Assessments") }}
                            </div>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none">
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M20.9556 5.90211L9.05063 19.5078L3.04199 13.4991L4.5004 12.0407L8.95017 16.4905L19.4034 4.54395L20.9556 5.90211Z"
                                        fill="#1D4ED8"
                                    /></svg
                                >{{ $t("Reporting and analytics") }}
                            </div>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none">
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M20.9556 5.90211L9.05063 19.5078L3.04199 13.4991L4.5004 12.0407L8.95017 16.4905L19.4034 4.54395L20.9556 5.90211Z"
                                        fill="#1D4ED8"
                                    /></svg
                                >{{ $t("Customer Support") }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pricing-card pro">
                    <div class="popular-span">
                        {{ $t("Most Popular") }}
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <g clip-path="url(#clip0_4754_17971)">
                                <path
                                    d="M6.49994 16.0009C6.34193 16.0017 6.18743 15.9543 6.0571 15.8649C5.92676 15.7756 5.8268 15.6486 5.77057 15.5009L4.52557 12.2634C4.50035 12.1982 4.46178 12.1389 4.41232 12.0894C4.36286 12.04 4.30362 12.0014 4.23838 11.9762L0.999941 10.7303C0.852423 10.6736 0.725541 10.5736 0.63604 10.4434C0.546538 10.3132 0.498626 10.1589 0.498626 10.0009C0.498626 9.84288 0.546538 9.68859 0.63604 9.55837C0.725541 9.42815 0.852423 9.32813 0.999941 9.27152L4.23744 8.02652C4.30268 8.0013 4.36193 7.96273 4.41138 7.91327C4.46084 7.86381 4.49941 7.80457 4.52463 7.73933L5.77057 4.50089C5.82718 4.35337 5.9272 4.22649 6.05742 4.13699C6.18764 4.04749 6.34193 3.99958 6.49994 3.99958C6.65795 3.99958 6.81225 4.04749 6.94246 4.13699C7.07268 4.22649 7.1727 4.35337 7.22932 4.50089L8.47432 7.73839C8.49953 7.80363 8.5381 7.86288 8.58756 7.91233C8.63702 7.96179 8.69627 8.00036 8.7615 8.02558L11.9803 9.26402C12.1338 9.32092 12.266 9.42375 12.359 9.55851C12.452 9.69327 12.5012 9.85342 12.4999 10.0171C12.4976 10.1724 12.4486 10.3234 12.3595 10.4505C12.2703 10.5776 12.1451 10.6751 11.9999 10.7303L8.76244 11.9753C8.6972 12.0005 8.63795 12.0391 8.5885 12.0885C8.53904 12.138 8.50047 12.1972 8.47525 12.2625L7.22932 15.5009C7.17308 15.6486 7.07312 15.7756 6.94279 15.8649C6.81245 15.9543 6.65796 16.0017 6.49994 16.0009Z"
                                    fill="white"
                                />
                                <path
                                    d="M2.74994 5.50089C2.6573 5.50089 2.56684 5.47282 2.49047 5.42038C2.4141 5.36794 2.35541 5.2936 2.32213 5.20714L1.79525 3.83714C1.78383 3.80718 1.76621 3.77997 1.74354 3.75729C1.72086 3.73462 1.69365 3.717 1.66369 3.70558L0.293691 3.1787C0.207247 3.14541 0.132915 3.08672 0.0804885 3.01035C0.0280618 2.93398 0 2.84352 0 2.75089C0 2.65826 0.0280618 2.5678 0.0804885 2.49143C0.132915 2.41506 0.207247 2.35637 0.293691 2.32308L1.66369 1.7962C1.69362 1.78473 1.72081 1.76709 1.74347 1.74442C1.76614 1.72176 1.78378 1.69457 1.79525 1.66464L2.31744 0.306828C2.34689 0.226891 2.39757 0.156491 2.46402 0.103193C2.53048 0.0498954 2.6102 0.0157158 2.69463 0.00432764C2.79599 -0.00799417 2.89856 0.0138741 2.98608 0.0664649C3.0736 0.119056 3.14105 0.199359 3.17775 0.29464L3.70463 1.66464C3.7161 1.69457 3.73374 1.72176 3.75641 1.74442C3.77908 1.76709 3.80626 1.78473 3.83619 1.7962L5.20619 2.32308C5.29263 2.35637 5.36697 2.41506 5.41939 2.49143C5.47182 2.5678 5.49988 2.65826 5.49988 2.75089C5.49988 2.84352 5.47182 2.93398 5.41939 3.01035C5.36697 3.08672 5.29263 3.14541 5.20619 3.1787L3.83619 3.70558C3.80623 3.717 3.77902 3.73462 3.75635 3.75729C3.73367 3.77997 3.71605 3.80718 3.70463 3.83714L3.17775 5.20714C3.14447 5.2936 3.08578 5.36794 3.00941 5.42038C2.93304 5.47282 2.84258 5.50089 2.74994 5.50089Z"
                                    fill="white"
                                />
                                <path
                                    d="M12.4999 8.00089C12.3989 8.00086 12.3002 7.9702 12.2169 7.91297C12.1336 7.85573 12.0696 7.7746 12.0334 7.68027L11.3196 5.82495C11.3071 5.79226 11.2878 5.76257 11.263 5.73781C11.2383 5.71304 11.2086 5.69376 11.1759 5.6812L9.32057 4.96745C9.22631 4.93113 9.14526 4.86712 9.0881 4.78383C9.03094 4.70055 9.00034 4.6019 9.00034 4.50089C9.00034 4.39988 9.03094 4.30123 9.0881 4.21795C9.14526 4.13466 9.22631 4.07065 9.32057 4.03433L11.1759 3.32058C11.2086 3.30802 11.2383 3.28874 11.263 3.26397C11.2878 3.23921 11.3071 3.20952 11.3196 3.17683L12.0281 1.33464C12.0604 1.24753 12.1158 1.17081 12.1882 1.11264C12.2607 1.05448 12.3476 1.01705 12.4396 1.00433C12.5502 0.990939 12.6621 1.01488 12.7576 1.07234C12.8531 1.1298 12.9266 1.2175 12.9665 1.32152L13.6803 3.17683C13.6928 3.20952 13.7121 3.23921 13.7369 3.26397C13.7616 3.28874 13.7913 3.30802 13.824 3.32058L15.6793 4.03433C15.7736 4.07065 15.8546 4.13466 15.9118 4.21795C15.9689 4.30123 15.9995 4.39988 15.9995 4.50089C15.9995 4.6019 15.9689 4.70055 15.9118 4.78383C15.8546 4.86712 15.7736 4.93113 15.6793 4.96745L13.824 5.6812C13.7913 5.69376 13.7616 5.71304 13.7369 5.73781C13.7121 5.76257 13.6928 5.79226 13.6803 5.82495L12.9665 7.68027C12.9302 7.7746 12.8663 7.85573 12.783 7.91297C12.6997 7.9702 12.601 8.00086 12.4999 8.00089Z"
                                    fill="white"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_4754_17971">
                                    <rect width="16" height="16" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                    <div class="pricing-header">
                        <div class="pricing-type">
                            <div class="h2">{{ $t("Pro") }}</div>
                            <div class="h5">{{ $t("Best for Staffing Companies") }}</div>
                        </div>
                        <div class="price-value">
                            <span>${{ Plan === "year" ? 250 : 295 }}</span
                            >{{ $t("/month") }}
                        </div>
                    </div>
                    <!-- @click="toggleEmailWindow(`Pro - ${Plan === 'year' ? 'yearly' : 'Mounthly'}`)" -->
                    <button class="pricing-card-button" @click="navigateToRegister(`Pro - ${Plan === 'year' ? 'yearly' : 'Mounthly'}`)">{{ $t("Get Started") }}</button>
                    <div class="pricing-features">
                        <div class="h5">{{ $t("Evolved Features:") }}</div>
                        <div class="infos-list">
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none">
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M20.9556 5.90211L9.05063 19.5078L3.04199 13.4991L4.5004 12.0407L8.95017 16.4905L19.4034 4.54395L20.9556 5.90211Z"
                                        fill="#1D4ED8"
                                    />
                                </svg>
                                5 {{ $t("Users") }}
                            </div>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none">
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M20.9556 5.90211L9.05063 19.5078L3.04199 13.4991L4.5004 12.0407L8.95017 16.4905L19.4034 4.54395L20.9556 5.90211Z"
                                        fill="#1D4ED8"
                                    /></svg
                                >{{ $t("5 Tests per Assessment") }}
                            </div>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none">
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M20.9556 5.90211L9.05063 19.5078L3.04199 13.4991L4.5004 12.0407L8.95017 16.4905L19.4034 4.54395L20.9556 5.90211Z"
                                        fill="#1D4ED8"
                                    /></svg
                                >300 {{ $t("Assessments") }}
                            </div>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none">
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M20.9556 5.90211L9.05063 19.5078L3.04199 13.4991L4.5004 12.0407L8.95017 16.4905L19.4034 4.54395L20.9556 5.90211Z"
                                        fill="#1D4ED8"
                                    /></svg
                                >{{ $t("Talent Marketplace") }}
                            </div>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none">
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M20.9556 5.90211L9.05063 19.5078L3.04199 13.4991L4.5004 12.0407L8.95017 16.4905L19.4034 4.54395L20.9556 5.90211Z"
                                        fill="#1D4ED8"
                                    /></svg
                                >{{ $t("Calender Integration") }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pricing-card">
                    <div class="pricing-header">
                        <div class="pricing-type">
                            <div class="h2">{{ $t("Enterprise") }}</div>
                            <div class="h5">{{ $t("Best for personal use") }}</div>
                        </div>
                        <div class="price-value">
                            <span>{{ $t("Custom") }}</span>
                        </div>
                    </div>
                    <!-- @click="toggleEmailWindow(`Enterprise - ${Plan === 'year' ? 'yearly' : 'Mounthly'}`)" -->
                    <button class="pricing-card-button" @click="navigateToRegister(`Enterprise - ${Plan === 'year' ? 'yearly' : 'Mounthly'}`)">{{ $t("Get Started") }}</button>
                    <div class="pricing-features">
                        <div class="h5">{{ $t("Unlimited features:") }}</div>
                        <div class="infos-list">
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none">
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M20.9556 5.90211L9.05063 19.5078L3.04199 13.4991L4.5004 12.0407L8.95017 16.4905L19.4034 4.54395L20.9556 5.90211Z"
                                        fill="#1D4ED8"
                                    />
                                </svg>
                                {{ $t("Unlimited Users") }}
                            </div>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none">
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M20.9556 5.90211L9.05063 19.5078L3.04199 13.4991L4.5004 12.0407L8.95017 16.4905L19.4034 4.54395L20.9556 5.90211Z"
                                        fill="#1D4ED8"
                                    /></svg
                                >{{ $t("5 Tests per Assessment") }}
                            </div>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none">
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M20.9556 5.90211L9.05063 19.5078L3.04199 13.4991L4.5004 12.0407L8.95017 16.4905L19.4034 4.54395L20.9556 5.90211Z"
                                        fill="#1D4ED8"
                                    /></svg
                                >{{ $t("Unlimited Assessments") }}
                            </div>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none">
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M20.9556 5.90211L9.05063 19.5078L3.04199 13.4991L4.5004 12.0407L8.95017 16.4905L19.4034 4.54395L20.9556 5.90211Z"
                                        fill="#1D4ED8"
                                    /></svg
                                >{{ $t("Automation") }}
                            </div>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none">
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M20.9556 5.90211L9.05063 19.5078L3.04199 13.4991L4.5004 12.0407L8.95017 16.4905L19.4034 4.54395L20.9556 5.90211Z"
                                        fill="#1D4ED8"
                                    /></svg
                                >{{ $t("Talent Marketplace") }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="learn-more">
                <h1 @click="showMore" v-if="!isShown">
                    <span>{{ $t("Click here") }}</span> {{ $t("To Compare Plans") }}
                </h1>
            </div>
        </section>
        <section v-if="isShown" class="feature-table">
            <div class="header-container">
                <div class="header header-two">
                    <h1>{{ $t("Feature Table") }}</h1>
                    <h4>{{ $t("Choose the perfect plan for your business needs") }}</h4>
                </div>
                <div class="saving-container">
                    <div class="save-span">
                        <span>{{ $t("Save 15%") }}</span> {{ $t("on monthly plan!") }}
                    </div>
                    <div class="time-container">
                        <button class="monthly">{{ $t("Monthly") }}</button>
                        <button class="yearly">{{ $t("Yearly") }}</button>
                    </div>
                </div>
            </div>
            <div class="cards-features">
                <div class="blank-container"></div>
                <div class="small-wrapper">
                    <div class="small-cards">
                        <div class="pricing-header">
                            <div class="pricing-type">
                                <div class="h2">{{ $t("Free") }}</div>
                            </div>
                            <div class="price-value"><span>$0</span>{{ $t("/month") }}</div>
                        </div>
                        <button class="pricing-card-button">{{ $t("Get Started") }}</button>
                    </div>
                    <div class="small-cards">
                        <div class="pricing-header">
                            <div class="pricing-type">
                                <div class="h2">{{ $t("Basic") }}</div>
                            </div>
                            <div class="price-value"><span>$99</span>{{ $t("/month") }}</div>
                        </div>
                        <button class="pricing-card-button">{{ $t("Get Started") }}</button>
                    </div>
                    <div class="small-cards">
                        <div class="pricing-header">
                            <div class="pricing-type">
                                <div class="h2">{{ $t("Pro") }}</div>
                            </div>
                            <div class="price-value"><span>$295</span>{{ $t("/month") }}</div>
                        </div>
                        <button class="pricing-card-button">{{ $t("Get Started") }}</button>
                    </div>
                    <div class="small-cards">
                        <div class="pricing-header">
                            <div class="pricing-type">
                                <div class="h2">{{ $t("Enterprise") }}</div>
                            </div>
                            <div class="price-value">
                                <span>{{ $t("Custom") }}</span>
                            </div>
                        </div>
                        <button class="pricing-card-button button-enterprise">
                            {{ $t("Get Started") }}
                        </button>
                    </div>
                </div>
            </div>

            <div class="table-container">
                <div class="tables table-one">
                    <div class="header-row">{{ $t("Evaluation") }}</div>
                    <div class="row">
                        <div class="first-column column">
                            {{ $t("Users") }}
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
                                <path
                                    d="M12 3C7.03125 3 3 7.03125 3 12C3 16.9688 7.03125 21 12 21C16.9688 21 21 16.9688 21 12C21 7.03125 16.9688 3 12 3ZM11.7188 17.25C11.5333 17.25 11.3521 17.195 11.1979 17.092C11.0437 16.989 10.9236 16.8426 10.8526 16.6713C10.7817 16.5 10.7631 16.3115 10.7993 16.1296C10.8354 15.9477 10.9247 15.7807 11.0558 15.6496C11.1869 15.5185 11.354 15.4292 11.5359 15.393C11.7177 15.3568 11.9062 15.3754 12.0775 15.4464C12.2488 15.5173 12.3952 15.6375 12.4983 15.7917C12.6013 15.9458 12.6562 16.1271 12.6562 16.3125C12.6562 16.5611 12.5575 16.7996 12.3817 16.9754C12.2058 17.1512 11.9674 17.25 11.7188 17.25ZM13.2863 12.4688C12.5264 12.9788 12.4219 13.4461 12.4219 13.875C12.4219 14.049 12.3527 14.216 12.2297 14.339C12.1066 14.4621 11.9397 14.5312 11.7656 14.5312C11.5916 14.5312 11.4247 14.4621 11.3016 14.339C11.1785 14.216 11.1094 14.049 11.1094 13.875C11.1094 12.848 11.5819 12.0314 12.5541 11.3784C13.4578 10.7719 13.9688 10.3875 13.9688 9.54234C13.9688 8.96766 13.6406 8.53125 12.9614 8.20828C12.8016 8.13234 12.4458 8.05828 12.008 8.06344C11.4586 8.07047 11.032 8.20172 10.7034 8.46609C10.0837 8.96484 10.0312 9.50766 10.0312 9.51562C10.0271 9.60181 10.006 9.68632 9.96919 9.76435C9.93237 9.84238 9.88054 9.9124 9.81667 9.9704C9.75279 10.0284 9.67811 10.0732 9.5969 10.1024C9.51569 10.1315 9.42954 10.1444 9.34336 10.1402C9.25718 10.1361 9.17266 10.115 9.09463 10.0782C9.0166 10.0414 8.94659 9.98953 8.88859 9.92565C8.83059 9.86177 8.78574 9.7871 8.7566 9.70589C8.72745 9.62468 8.71459 9.53852 8.71875 9.45234C8.72391 9.33844 8.80313 8.31234 9.87984 7.44609C10.4381 6.99703 11.1483 6.76359 11.9892 6.75328C12.5845 6.74625 13.1437 6.84703 13.523 7.02609C14.6578 7.56281 15.2812 8.45766 15.2812 9.54234C15.2812 11.1281 14.2214 11.8402 13.2863 12.4688Z"
                                    fill="#CECECE"
                                />
                            </svg>
                        </div>
                        <div class="second-column column">1</div>
                        <div class="third-column column">3</div>
                        <div class="fourth-column column">5</div>
                        <div class="fifth-column column">{{ $t("Unlimited") }}</div>
                    </div>
                    <div class="row">
                        <div class="first-column column">
                            {{ $t("Assessment") }}
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
                                <path
                                    d="M12 3C7.03125 3 3 7.03125 3 12C3 16.9688 7.03125 21 12 21C16.9688 21 21 16.9688 21 12C21 7.03125 16.9688 3 12 3ZM11.7188 17.25C11.5333 17.25 11.3521 17.195 11.1979 17.092C11.0437 16.989 10.9236 16.8426 10.8526 16.6713C10.7817 16.5 10.7631 16.3115 10.7993 16.1296C10.8354 15.9477 10.9247 15.7807 11.0558 15.6496C11.1869 15.5185 11.354 15.4292 11.5359 15.393C11.7177 15.3568 11.9062 15.3754 12.0775 15.4464C12.2488 15.5173 12.3952 15.6375 12.4983 15.7917C12.6013 15.9458 12.6562 16.1271 12.6562 16.3125C12.6562 16.5611 12.5575 16.7996 12.3817 16.9754C12.2058 17.1512 11.9674 17.25 11.7188 17.25ZM13.2863 12.4688C12.5264 12.9788 12.4219 13.4461 12.4219 13.875C12.4219 14.049 12.3527 14.216 12.2297 14.339C12.1066 14.4621 11.9397 14.5312 11.7656 14.5312C11.5916 14.5312 11.4247 14.4621 11.3016 14.339C11.1785 14.216 11.1094 14.049 11.1094 13.875C11.1094 12.848 11.5819 12.0314 12.5541 11.3784C13.4578 10.7719 13.9688 10.3875 13.9688 9.54234C13.9688 8.96766 13.6406 8.53125 12.9614 8.20828C12.8016 8.13234 12.4458 8.05828 12.008 8.06344C11.4586 8.07047 11.032 8.20172 10.7034 8.46609C10.0837 8.96484 10.0312 9.50766 10.0312 9.51562C10.0271 9.60181 10.006 9.68632 9.96919 9.76435C9.93237 9.84238 9.88054 9.9124 9.81667 9.9704C9.75279 10.0284 9.67811 10.0732 9.5969 10.1024C9.51569 10.1315 9.42954 10.1444 9.34336 10.1402C9.25718 10.1361 9.17266 10.115 9.09463 10.0782C9.0166 10.0414 8.94659 9.98953 8.88859 9.92565C8.83059 9.86177 8.78574 9.7871 8.7566 9.70589C8.72745 9.62468 8.71459 9.53852 8.71875 9.45234C8.72391 9.33844 8.80313 8.31234 9.87984 7.44609C10.4381 6.99703 11.1483 6.76359 11.9892 6.75328C12.5845 6.74625 13.1437 6.84703 13.523 7.02609C14.6578 7.56281 15.2812 8.45766 15.2812 9.54234C15.2812 11.1281 14.2214 11.8402 13.2863 12.4688Z"
                                    fill="#CECECE"
                                />
                            </svg>
                        </div>
                        <div class="second-column column">10</div>
                        <div class="third-column column">50</div>
                        <div class="fourth-column column">300</div>
                        <div class="fifth-column column">{{ $t("Unlimited") }}</div>
                    </div>
                    <div class="row">
                        <div class="first-column column">
                            {{ $t("Tests Per Assessment") }}
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
                                <path
                                    d="M12 3C7.03125 3 3 7.03125 3 12C3 16.9688 7.03125 21 12 21C16.9688 21 21 16.9688 21 12C21 7.03125 16.9688 3 12 3ZM11.7188 17.25C11.5333 17.25 11.3521 17.195 11.1979 17.092C11.0437 16.989 10.9236 16.8426 10.8526 16.6713C10.7817 16.5 10.7631 16.3115 10.7993 16.1296C10.8354 15.9477 10.9247 15.7807 11.0558 15.6496C11.1869 15.5185 11.354 15.4292 11.5359 15.393C11.7177 15.3568 11.9062 15.3754 12.0775 15.4464C12.2488 15.5173 12.3952 15.6375 12.4983 15.7917C12.6013 15.9458 12.6562 16.1271 12.6562 16.3125C12.6562 16.5611 12.5575 16.7996 12.3817 16.9754C12.2058 17.1512 11.9674 17.25 11.7188 17.25ZM13.2863 12.4688C12.5264 12.9788 12.4219 13.4461 12.4219 13.875C12.4219 14.049 12.3527 14.216 12.2297 14.339C12.1066 14.4621 11.9397 14.5312 11.7656 14.5312C11.5916 14.5312 11.4247 14.4621 11.3016 14.339C11.1785 14.216 11.1094 14.049 11.1094 13.875C11.1094 12.848 11.5819 12.0314 12.5541 11.3784C13.4578 10.7719 13.9688 10.3875 13.9688 9.54234C13.9688 8.96766 13.6406 8.53125 12.9614 8.20828C12.8016 8.13234 12.4458 8.05828 12.008 8.06344C11.4586 8.07047 11.032 8.20172 10.7034 8.46609C10.0837 8.96484 10.0312 9.50766 10.0312 9.51562C10.0271 9.60181 10.006 9.68632 9.96919 9.76435C9.93237 9.84238 9.88054 9.9124 9.81667 9.9704C9.75279 10.0284 9.67811 10.0732 9.5969 10.1024C9.51569 10.1315 9.42954 10.1444 9.34336 10.1402C9.25718 10.1361 9.17266 10.115 9.09463 10.0782C9.0166 10.0414 8.94659 9.98953 8.88859 9.92565C8.83059 9.86177 8.78574 9.7871 8.7566 9.70589C8.72745 9.62468 8.71459 9.53852 8.71875 9.45234C8.72391 9.33844 8.80313 8.31234 9.87984 7.44609C10.4381 6.99703 11.1483 6.76359 11.9892 6.75328C12.5845 6.74625 13.1437 6.84703 13.523 7.02609C14.6578 7.56281 15.2812 8.45766 15.2812 9.54234C15.2812 11.1281 14.2214 11.8402 13.2863 12.4688Z"
                                    fill="#CECECE"
                                />
                            </svg>
                        </div>
                        <div class="second-column column">5</div>
                        <div class="third-column column">5</div>
                        <div class="fourth-column column">5</div>
                        <div class="fifth-column column">5</div>
                    </div>
                    <div class="row">
                        <div class="first-column column">
                            {{ $t("Assessment Library") }}
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
                                <path
                                    d="M12 3C7.03125 3 3 7.03125 3 12C3 16.9688 7.03125 21 12 21C16.9688 21 21 16.9688 21 12C21 7.03125 16.9688 3 12 3ZM11.7188 17.25C11.5333 17.25 11.3521 17.195 11.1979 17.092C11.0437 16.989 10.9236 16.8426 10.8526 16.6713C10.7817 16.5 10.7631 16.3115 10.7993 16.1296C10.8354 15.9477 10.9247 15.7807 11.0558 15.6496C11.1869 15.5185 11.354 15.4292 11.5359 15.393C11.7177 15.3568 11.9062 15.3754 12.0775 15.4464C12.2488 15.5173 12.3952 15.6375 12.4983 15.7917C12.6013 15.9458 12.6562 16.1271 12.6562 16.3125C12.6562 16.5611 12.5575 16.7996 12.3817 16.9754C12.2058 17.1512 11.9674 17.25 11.7188 17.25ZM13.2863 12.4688C12.5264 12.9788 12.4219 13.4461 12.4219 13.875C12.4219 14.049 12.3527 14.216 12.2297 14.339C12.1066 14.4621 11.9397 14.5312 11.7656 14.5312C11.5916 14.5312 11.4247 14.4621 11.3016 14.339C11.1785 14.216 11.1094 14.049 11.1094 13.875C11.1094 12.848 11.5819 12.0314 12.5541 11.3784C13.4578 10.7719 13.9688 10.3875 13.9688 9.54234C13.9688 8.96766 13.6406 8.53125 12.9614 8.20828C12.8016 8.13234 12.4458 8.05828 12.008 8.06344C11.4586 8.07047 11.032 8.20172 10.7034 8.46609C10.0837 8.96484 10.0312 9.50766 10.0312 9.51562C10.0271 9.60181 10.006 9.68632 9.96919 9.76435C9.93237 9.84238 9.88054 9.9124 9.81667 9.9704C9.75279 10.0284 9.67811 10.0732 9.5969 10.1024C9.51569 10.1315 9.42954 10.1444 9.34336 10.1402C9.25718 10.1361 9.17266 10.115 9.09463 10.0782C9.0166 10.0414 8.94659 9.98953 8.88859 9.92565C8.83059 9.86177 8.78574 9.7871 8.7566 9.70589C8.72745 9.62468 8.71459 9.53852 8.71875 9.45234C8.72391 9.33844 8.80313 8.31234 9.87984 7.44609C10.4381 6.99703 11.1483 6.76359 11.9892 6.75328C12.5845 6.74625 13.1437 6.84703 13.523 7.02609C14.6578 7.56281 15.2812 8.45766 15.2812 9.54234C15.2812 11.1281 14.2214 11.8402 13.2863 12.4688Z"
                                    fill="#CECECE"
                                />
                            </svg>
                        </div>
                        <div class="second-column column">
                            <img loading="lazy" decoding="async" src="@/assets/checkmark-2.svg" alt="" />
                        </div>
                        <div class="third-column column">
                            <img loading="lazy" decoding="async" src="@/assets/checkmark-2.svg" alt="" />
                        </div>
                        <div class="fourth-column column">
                            <img loading="lazy" decoding="async" src="@/assets/checkmark-2.svg" alt="" />
                        </div>
                        <div class="fifth-column column">
                            <img loading="lazy" decoding="async" src="@/assets/checkmark-2.svg" alt="" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="first-column column">
                            {{ $t("Coding Languages") }}
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
                                <path
                                    d="M12 3C7.03125 3 3 7.03125 3 12C3 16.9688 7.03125 21 12 21C16.9688 21 21 16.9688 21 12C21 7.03125 16.9688 3 12 3ZM11.7188 17.25C11.5333 17.25 11.3521 17.195 11.1979 17.092C11.0437 16.989 10.9236 16.8426 10.8526 16.6713C10.7817 16.5 10.7631 16.3115 10.7993 16.1296C10.8354 15.9477 10.9247 15.7807 11.0558 15.6496C11.1869 15.5185 11.354 15.4292 11.5359 15.393C11.7177 15.3568 11.9062 15.3754 12.0775 15.4464C12.2488 15.5173 12.3952 15.6375 12.4983 15.7917C12.6013 15.9458 12.6562 16.1271 12.6562 16.3125C12.6562 16.5611 12.5575 16.7996 12.3817 16.9754C12.2058 17.1512 11.9674 17.25 11.7188 17.25ZM13.2863 12.4688C12.5264 12.9788 12.4219 13.4461 12.4219 13.875C12.4219 14.049 12.3527 14.216 12.2297 14.339C12.1066 14.4621 11.9397 14.5312 11.7656 14.5312C11.5916 14.5312 11.4247 14.4621 11.3016 14.339C11.1785 14.216 11.1094 14.049 11.1094 13.875C11.1094 12.848 11.5819 12.0314 12.5541 11.3784C13.4578 10.7719 13.9688 10.3875 13.9688 9.54234C13.9688 8.96766 13.6406 8.53125 12.9614 8.20828C12.8016 8.13234 12.4458 8.05828 12.008 8.06344C11.4586 8.07047 11.032 8.20172 10.7034 8.46609C10.0837 8.96484 10.0312 9.50766 10.0312 9.51562C10.0271 9.60181 10.006 9.68632 9.96919 9.76435C9.93237 9.84238 9.88054 9.9124 9.81667 9.9704C9.75279 10.0284 9.67811 10.0732 9.5969 10.1024C9.51569 10.1315 9.42954 10.1444 9.34336 10.1402C9.25718 10.1361 9.17266 10.115 9.09463 10.0782C9.0166 10.0414 8.94659 9.98953 8.88859 9.92565C8.83059 9.86177 8.78574 9.7871 8.7566 9.70589C8.72745 9.62468 8.71459 9.53852 8.71875 9.45234C8.72391 9.33844 8.80313 8.31234 9.87984 7.44609C10.4381 6.99703 11.1483 6.76359 11.9892 6.75328C12.5845 6.74625 13.1437 6.84703 13.523 7.02609C14.6578 7.56281 15.2812 8.45766 15.2812 9.54234C15.2812 11.1281 14.2214 11.8402 13.2863 12.4688Z"
                                    fill="#CECECE"
                                />
                            </svg>
                        </div>
                        <div class="second-column column">Limited</div>
                        <div class="third-column column">
                            <img loading="lazy" decoding="async" src="@/assets/checkmark-2.svg" alt="" />
                        </div>
                        <div class="fourth-column column">
                            <img loading="lazy" decoding="async" src="@/assets/checkmark-2.svg" alt="" />
                        </div>
                        <div class="fifth-column column">
                            <img loading="lazy" decoding="async" src="@/assets/checkmark-2.svg" alt="" />
                        </div>
                    </div>
                </div>
                <div class="tables table-two">
                    <div class="header-row">Flow</div>
                    <div class="row">
                        <div class="first-column column">
                            {{ $t("Automation") }}
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
                                <path
                                    d="M12 3C7.03125 3 3 7.03125 3 12C3 16.9688 7.03125 21 12 21C16.9688 21 21 16.9688 21 12C21 7.03125 16.9688 3 12 3ZM11.7188 17.25C11.5333 17.25 11.3521 17.195 11.1979 17.092C11.0437 16.989 10.9236 16.8426 10.8526 16.6713C10.7817 16.5 10.7631 16.3115 10.7993 16.1296C10.8354 15.9477 10.9247 15.7807 11.0558 15.6496C11.1869 15.5185 11.354 15.4292 11.5359 15.393C11.7177 15.3568 11.9062 15.3754 12.0775 15.4464C12.2488 15.5173 12.3952 15.6375 12.4983 15.7917C12.6013 15.9458 12.6562 16.1271 12.6562 16.3125C12.6562 16.5611 12.5575 16.7996 12.3817 16.9754C12.2058 17.1512 11.9674 17.25 11.7188 17.25ZM13.2863 12.4688C12.5264 12.9788 12.4219 13.4461 12.4219 13.875C12.4219 14.049 12.3527 14.216 12.2297 14.339C12.1066 14.4621 11.9397 14.5312 11.7656 14.5312C11.5916 14.5312 11.4247 14.4621 11.3016 14.339C11.1785 14.216 11.1094 14.049 11.1094 13.875C11.1094 12.848 11.5819 12.0314 12.5541 11.3784C13.4578 10.7719 13.9688 10.3875 13.9688 9.54234C13.9688 8.96766 13.6406 8.53125 12.9614 8.20828C12.8016 8.13234 12.4458 8.05828 12.008 8.06344C11.4586 8.07047 11.032 8.20172 10.7034 8.46609C10.0837 8.96484 10.0312 9.50766 10.0312 9.51562C10.0271 9.60181 10.006 9.68632 9.96919 9.76435C9.93237 9.84238 9.88054 9.9124 9.81667 9.9704C9.75279 10.0284 9.67811 10.0732 9.5969 10.1024C9.51569 10.1315 9.42954 10.1444 9.34336 10.1402C9.25718 10.1361 9.17266 10.115 9.09463 10.0782C9.0166 10.0414 8.94659 9.98953 8.88859 9.92565C8.83059 9.86177 8.78574 9.7871 8.7566 9.70589C8.72745 9.62468 8.71459 9.53852 8.71875 9.45234C8.72391 9.33844 8.80313 8.31234 9.87984 7.44609C10.4381 6.99703 11.1483 6.76359 11.9892 6.75328C12.5845 6.74625 13.1437 6.84703 13.523 7.02609C14.6578 7.56281 15.2812 8.45766 15.2812 9.54234C15.2812 11.1281 14.2214 11.8402 13.2863 12.4688Z"
                                    fill="#CECECE"
                                />
                            </svg>
                        </div>
                        <div class="second-column column">-</div>
                        <div class="third-column column">-</div>
                        <div class="fourth-column column">
                            <img loading="lazy" decoding="async" src="@/assets/checkmark-2.svg" alt="" />
                        </div>
                        <div class="fifth-column column">
                            <img loading="lazy" decoding="async" src="@/assets/checkmark-2.svg" alt="" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="first-column column">
                            {{ $t("Reporting And Analytics") }}
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
                                <path
                                    d="M12 3C7.03125 3 3 7.03125 3 12C3 16.9688 7.03125 21 12 21C16.9688 21 21 16.9688 21 12C21 7.03125 16.9688 3 12 3ZM11.7188 17.25C11.5333 17.25 11.3521 17.195 11.1979 17.092C11.0437 16.989 10.9236 16.8426 10.8526 16.6713C10.7817 16.5 10.7631 16.3115 10.7993 16.1296C10.8354 15.9477 10.9247 15.7807 11.0558 15.6496C11.1869 15.5185 11.354 15.4292 11.5359 15.393C11.7177 15.3568 11.9062 15.3754 12.0775 15.4464C12.2488 15.5173 12.3952 15.6375 12.4983 15.7917C12.6013 15.9458 12.6562 16.1271 12.6562 16.3125C12.6562 16.5611 12.5575 16.7996 12.3817 16.9754C12.2058 17.1512 11.9674 17.25 11.7188 17.25ZM13.2863 12.4688C12.5264 12.9788 12.4219 13.4461 12.4219 13.875C12.4219 14.049 12.3527 14.216 12.2297 14.339C12.1066 14.4621 11.9397 14.5312 11.7656 14.5312C11.5916 14.5312 11.4247 14.4621 11.3016 14.339C11.1785 14.216 11.1094 14.049 11.1094 13.875C11.1094 12.848 11.5819 12.0314 12.5541 11.3784C13.4578 10.7719 13.9688 10.3875 13.9688 9.54234C13.9688 8.96766 13.6406 8.53125 12.9614 8.20828C12.8016 8.13234 12.4458 8.05828 12.008 8.06344C11.4586 8.07047 11.032 8.20172 10.7034 8.46609C10.0837 8.96484 10.0312 9.50766 10.0312 9.51562C10.0271 9.60181 10.006 9.68632 9.96919 9.76435C9.93237 9.84238 9.88054 9.9124 9.81667 9.9704C9.75279 10.0284 9.67811 10.0732 9.5969 10.1024C9.51569 10.1315 9.42954 10.1444 9.34336 10.1402C9.25718 10.1361 9.17266 10.115 9.09463 10.0782C9.0166 10.0414 8.94659 9.98953 8.88859 9.92565C8.83059 9.86177 8.78574 9.7871 8.7566 9.70589C8.72745 9.62468 8.71459 9.53852 8.71875 9.45234C8.72391 9.33844 8.80313 8.31234 9.87984 7.44609C10.4381 6.99703 11.1483 6.76359 11.9892 6.75328C12.5845 6.74625 13.1437 6.84703 13.523 7.02609C14.6578 7.56281 15.2812 8.45766 15.2812 9.54234C15.2812 11.1281 14.2214 11.8402 13.2863 12.4688Z"
                                    fill="#CECECE"
                                />
                            </svg>
                        </div>
                        <div class="second-column column">
                            <img loading="lazy" decoding="async" src="@/assets/checkmark-2.svg" alt="" />
                        </div>
                        <div class="third-column column">
                            <img loading="lazy" decoding="async" src="@/assets/checkmark-2.svg" alt="" />
                        </div>
                        <div class="fourth-column column">
                            <img loading="lazy" decoding="async" src="@/assets/checkmark-2.svg" alt="" />
                        </div>
                        <div class="fifth-column column">
                            <img loading="lazy" decoding="async" src="@/assets/checkmark-2.svg" alt="" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="first-column column">
                            {{ $t("Talent Marketplace") }}
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
                                <path
                                    d="M12 3C7.03125 3 3 7.03125 3 12C3 16.9688 7.03125 21 12 21C16.9688 21 21 16.9688 21 12C21 7.03125 16.9688 3 12 3ZM11.7188 17.25C11.5333 17.25 11.3521 17.195 11.1979 17.092C11.0437 16.989 10.9236 16.8426 10.8526 16.6713C10.7817 16.5 10.7631 16.3115 10.7993 16.1296C10.8354 15.9477 10.9247 15.7807 11.0558 15.6496C11.1869 15.5185 11.354 15.4292 11.5359 15.393C11.7177 15.3568 11.9062 15.3754 12.0775 15.4464C12.2488 15.5173 12.3952 15.6375 12.4983 15.7917C12.6013 15.9458 12.6562 16.1271 12.6562 16.3125C12.6562 16.5611 12.5575 16.7996 12.3817 16.9754C12.2058 17.1512 11.9674 17.25 11.7188 17.25ZM13.2863 12.4688C12.5264 12.9788 12.4219 13.4461 12.4219 13.875C12.4219 14.049 12.3527 14.216 12.2297 14.339C12.1066 14.4621 11.9397 14.5312 11.7656 14.5312C11.5916 14.5312 11.4247 14.4621 11.3016 14.339C11.1785 14.216 11.1094 14.049 11.1094 13.875C11.1094 12.848 11.5819 12.0314 12.5541 11.3784C13.4578 10.7719 13.9688 10.3875 13.9688 9.54234C13.9688 8.96766 13.6406 8.53125 12.9614 8.20828C12.8016 8.13234 12.4458 8.05828 12.008 8.06344C11.4586 8.07047 11.032 8.20172 10.7034 8.46609C10.0837 8.96484 10.0312 9.50766 10.0312 9.51562C10.0271 9.60181 10.006 9.68632 9.96919 9.76435C9.93237 9.84238 9.88054 9.9124 9.81667 9.9704C9.75279 10.0284 9.67811 10.0732 9.5969 10.1024C9.51569 10.1315 9.42954 10.1444 9.34336 10.1402C9.25718 10.1361 9.17266 10.115 9.09463 10.0782C9.0166 10.0414 8.94659 9.98953 8.88859 9.92565C8.83059 9.86177 8.78574 9.7871 8.7566 9.70589C8.72745 9.62468 8.71459 9.53852 8.71875 9.45234C8.72391 9.33844 8.80313 8.31234 9.87984 7.44609C10.4381 6.99703 11.1483 6.76359 11.9892 6.75328C12.5845 6.74625 13.1437 6.84703 13.523 7.02609C14.6578 7.56281 15.2812 8.45766 15.2812 9.54234C15.2812 11.1281 14.2214 11.8402 13.2863 12.4688Z"
                                    fill="#CECECE"
                                />
                            </svg>
                        </div>
                        <div class="second-column column">-</div>
                        <div class="third-column column">-</div>
                        <div class="fourth-column column">
                            <img loading="lazy" decoding="async" src="@/assets/checkmark-2.svg" alt="" />
                        </div>
                        <div class="fifth-column column">
                            <img loading="lazy" decoding="async" src="@/assets/checkmark-2.svg" alt="" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="first-column column">
                            {{ $t("Customer Support") }}
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
                                <path
                                    d="M12 3C7.03125 3 3 7.03125 3 12C3 16.9688 7.03125 21 12 21C16.9688 21 21 16.9688 21 12C21 7.03125 16.9688 3 12 3ZM11.7188 17.25C11.5333 17.25 11.3521 17.195 11.1979 17.092C11.0437 16.989 10.9236 16.8426 10.8526 16.6713C10.7817 16.5 10.7631 16.3115 10.7993 16.1296C10.8354 15.9477 10.9247 15.7807 11.0558 15.6496C11.1869 15.5185 11.354 15.4292 11.5359 15.393C11.7177 15.3568 11.9062 15.3754 12.0775 15.4464C12.2488 15.5173 12.3952 15.6375 12.4983 15.7917C12.6013 15.9458 12.6562 16.1271 12.6562 16.3125C12.6562 16.5611 12.5575 16.7996 12.3817 16.9754C12.2058 17.1512 11.9674 17.25 11.7188 17.25ZM13.2863 12.4688C12.5264 12.9788 12.4219 13.4461 12.4219 13.875C12.4219 14.049 12.3527 14.216 12.2297 14.339C12.1066 14.4621 11.9397 14.5312 11.7656 14.5312C11.5916 14.5312 11.4247 14.4621 11.3016 14.339C11.1785 14.216 11.1094 14.049 11.1094 13.875C11.1094 12.848 11.5819 12.0314 12.5541 11.3784C13.4578 10.7719 13.9688 10.3875 13.9688 9.54234C13.9688 8.96766 13.6406 8.53125 12.9614 8.20828C12.8016 8.13234 12.4458 8.05828 12.008 8.06344C11.4586 8.07047 11.032 8.20172 10.7034 8.46609C10.0837 8.96484 10.0312 9.50766 10.0312 9.51562C10.0271 9.60181 10.006 9.68632 9.96919 9.76435C9.93237 9.84238 9.88054 9.9124 9.81667 9.9704C9.75279 10.0284 9.67811 10.0732 9.5969 10.1024C9.51569 10.1315 9.42954 10.1444 9.34336 10.1402C9.25718 10.1361 9.17266 10.115 9.09463 10.0782C9.0166 10.0414 8.94659 9.98953 8.88859 9.92565C8.83059 9.86177 8.78574 9.7871 8.7566 9.70589C8.72745 9.62468 8.71459 9.53852 8.71875 9.45234C8.72391 9.33844 8.80313 8.31234 9.87984 7.44609C10.4381 6.99703 11.1483 6.76359 11.9892 6.75328C12.5845 6.74625 13.1437 6.84703 13.523 7.02609C14.6578 7.56281 15.2812 8.45766 15.2812 9.54234C15.2812 11.1281 14.2214 11.8402 13.2863 12.4688Z"
                                    fill="#CECECE"
                                />
                            </svg>
                        </div>
                        <div class="second-column column">-</div>

                        <div class="third-column column">
                            <img loading="lazy" decoding="async" src="@/assets/checkmark-2.svg" alt="" />
                        </div>
                        <div class="fourth-column column">
                            <img loading="lazy" decoding="async" src="@/assets/checkmark-2.svg" alt="" />
                        </div>
                        <div class="fifth-column column">
                            <img loading="lazy" decoding="async" src="@/assets/checkmark-2.svg" alt="" />
                        </div>
                    </div>
                </div>
                <div class="tables table-three">
                    <div class="header-row">{{ $t("Adds on") }}</div>
                    <div class="row">
                        <div class="first-column column">
                            {{ $t("Calendar Integration") }}
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
                                <path
                                    d="M12 3C7.03125 3 3 7.03125 3 12C3 16.9688 7.03125 21 12 21C16.9688 21 21 16.9688 21 12C21 7.03125 16.9688 3 12 3ZM11.7188 17.25C11.5333 17.25 11.3521 17.195 11.1979 17.092C11.0437 16.989 10.9236 16.8426 10.8526 16.6713C10.7817 16.5 10.7631 16.3115 10.7993 16.1296C10.8354 15.9477 10.9247 15.7807 11.0558 15.6496C11.1869 15.5185 11.354 15.4292 11.5359 15.393C11.7177 15.3568 11.9062 15.3754 12.0775 15.4464C12.2488 15.5173 12.3952 15.6375 12.4983 15.7917C12.6013 15.9458 12.6562 16.1271 12.6562 16.3125C12.6562 16.5611 12.5575 16.7996 12.3817 16.9754C12.2058 17.1512 11.9674 17.25 11.7188 17.25ZM13.2863 12.4688C12.5264 12.9788 12.4219 13.4461 12.4219 13.875C12.4219 14.049 12.3527 14.216 12.2297 14.339C12.1066 14.4621 11.9397 14.5312 11.7656 14.5312C11.5916 14.5312 11.4247 14.4621 11.3016 14.339C11.1785 14.216 11.1094 14.049 11.1094 13.875C11.1094 12.848 11.5819 12.0314 12.5541 11.3784C13.4578 10.7719 13.9688 10.3875 13.9688 9.54234C13.9688 8.96766 13.6406 8.53125 12.9614 8.20828C12.8016 8.13234 12.4458 8.05828 12.008 8.06344C11.4586 8.07047 11.032 8.20172 10.7034 8.46609C10.0837 8.96484 10.0312 9.50766 10.0312 9.51562C10.0271 9.60181 10.006 9.68632 9.96919 9.76435C9.93237 9.84238 9.88054 9.9124 9.81667 9.9704C9.75279 10.0284 9.67811 10.0732 9.5969 10.1024C9.51569 10.1315 9.42954 10.1444 9.34336 10.1402C9.25718 10.1361 9.17266 10.115 9.09463 10.0782C9.0166 10.0414 8.94659 9.98953 8.88859 9.92565C8.83059 9.86177 8.78574 9.7871 8.7566 9.70589C8.72745 9.62468 8.71459 9.53852 8.71875 9.45234C8.72391 9.33844 8.80313 8.31234 9.87984 7.44609C10.4381 6.99703 11.1483 6.76359 11.9892 6.75328C12.5845 6.74625 13.1437 6.84703 13.523 7.02609C14.6578 7.56281 15.2812 8.45766 15.2812 9.54234C15.2812 11.1281 14.2214 11.8402 13.2863 12.4688Z"
                                    fill="#CECECE"
                                />
                            </svg>
                        </div>
                        <div class="second-column column">-</div>

                        <div class="third-column column">
                            <img loading="lazy" decoding="async" src="@/assets/checkmark-2.svg" alt="" />
                        </div>
                        <div class="fourth-column column">
                            <img loading="lazy" decoding="async" src="@/assets/checkmark-2.svg" alt="" />
                        </div>
                        <div class="fifth-column column">
                            <img loading="lazy" decoding="async" src="@/assets/checkmark-2.svg" alt="" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="first-column column">
                            {{ $t("Assessment templates based on job roles") }}
                            <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 24 24" fill="none">
                                <path
                                    d="M12 3C7.03125 3 3 7.03125 3 12C3 16.9688 7.03125 21 12 21C16.9688 21 21 16.9688 21 12C21 7.03125 16.9688 3 12 3ZM11.7188 17.25C11.5333 17.25 11.3521 17.195 11.1979 17.092C11.0437 16.989 10.9236 16.8426 10.8526 16.6713C10.7817 16.5 10.7631 16.3115 10.7993 16.1296C10.8354 15.9477 10.9247 15.7807 11.0558 15.6496C11.1869 15.5185 11.354 15.4292 11.5359 15.393C11.7177 15.3568 11.9062 15.3754 12.0775 15.4464C12.2488 15.5173 12.3952 15.6375 12.4983 15.7917C12.6013 15.9458 12.6562 16.1271 12.6562 16.3125C12.6562 16.5611 12.5575 16.7996 12.3817 16.9754C12.2058 17.1512 11.9674 17.25 11.7188 17.25ZM13.2863 12.4688C12.5264 12.9788 12.4219 13.4461 12.4219 13.875C12.4219 14.049 12.3527 14.216 12.2297 14.339C12.1066 14.4621 11.9397 14.5312 11.7656 14.5312C11.5916 14.5312 11.4247 14.4621 11.3016 14.339C11.1785 14.216 11.1094 14.049 11.1094 13.875C11.1094 12.848 11.5819 12.0314 12.5541 11.3784C13.4578 10.7719 13.9688 10.3875 13.9688 9.54234C13.9688 8.96766 13.6406 8.53125 12.9614 8.20828C12.8016 8.13234 12.4458 8.05828 12.008 8.06344C11.4586 8.07047 11.032 8.20172 10.7034 8.46609C10.0837 8.96484 10.0312 9.50766 10.0312 9.51562C10.0271 9.60181 10.006 9.68632 9.96919 9.76435C9.93237 9.84238 9.88054 9.9124 9.81667 9.9704C9.75279 10.0284 9.67811 10.0732 9.5969 10.1024C9.51569 10.1315 9.42954 10.1444 9.34336 10.1402C9.25718 10.1361 9.17266 10.115 9.09463 10.0782C9.0166 10.0414 8.94659 9.98953 8.88859 9.92565C8.83059 9.86177 8.78574 9.7871 8.7566 9.70589C8.72745 9.62468 8.71459 9.53852 8.71875 9.45234C8.72391 9.33844 8.80313 8.31234 9.87984 7.44609C10.4381 6.99703 11.1483 6.76359 11.9892 6.75328C12.5845 6.74625 13.1437 6.84703 13.523 7.02609C14.6578 7.56281 15.2812 8.45766 15.2812 9.54234C15.2812 11.1281 14.2214 11.8402 13.2863 12.4688Z"
                                    fill="#CECECE"
                                />
                            </svg>
                        </div>
                        <div class="second-column column">-</div>
                        <div class="third-column column">-</div>
                        <div class="fourth-column column">
                            <img loading="lazy" decoding="async" src="@/assets/checkmark-2.svg" alt="" />
                        </div>
                        <div class="fifth-column column">
                            <img loading="lazy" decoding="async" src="@/assets/checkmark-2.svg" alt="" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="first-column column">
                            {{ $t("Corporate Assessments") }}
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
                                <path
                                    d="M12 3C7.03125 3 3 7.03125 3 12C3 16.9688 7.03125 21 12 21C16.9688 21 21 16.9688 21 12C21 7.03125 16.9688 3 12 3ZM11.7188 17.25C11.5333 17.25 11.3521 17.195 11.1979 17.092C11.0437 16.989 10.9236 16.8426 10.8526 16.6713C10.7817 16.5 10.7631 16.3115 10.7993 16.1296C10.8354 15.9477 10.9247 15.7807 11.0558 15.6496C11.1869 15.5185 11.354 15.4292 11.5359 15.393C11.7177 15.3568 11.9062 15.3754 12.0775 15.4464C12.2488 15.5173 12.3952 15.6375 12.4983 15.7917C12.6013 15.9458 12.6562 16.1271 12.6562 16.3125C12.6562 16.5611 12.5575 16.7996 12.3817 16.9754C12.2058 17.1512 11.9674 17.25 11.7188 17.25ZM13.2863 12.4688C12.5264 12.9788 12.4219 13.4461 12.4219 13.875C12.4219 14.049 12.3527 14.216 12.2297 14.339C12.1066 14.4621 11.9397 14.5312 11.7656 14.5312C11.5916 14.5312 11.4247 14.4621 11.3016 14.339C11.1785 14.216 11.1094 14.049 11.1094 13.875C11.1094 12.848 11.5819 12.0314 12.5541 11.3784C13.4578 10.7719 13.9688 10.3875 13.9688 9.54234C13.9688 8.96766 13.6406 8.53125 12.9614 8.20828C12.8016 8.13234 12.4458 8.05828 12.008 8.06344C11.4586 8.07047 11.032 8.20172 10.7034 8.46609C10.0837 8.96484 10.0312 9.50766 10.0312 9.51562C10.0271 9.60181 10.006 9.68632 9.96919 9.76435C9.93237 9.84238 9.88054 9.9124 9.81667 9.9704C9.75279 10.0284 9.67811 10.0732 9.5969 10.1024C9.51569 10.1315 9.42954 10.1444 9.34336 10.1402C9.25718 10.1361 9.17266 10.115 9.09463 10.0782C9.0166 10.0414 8.94659 9.98953 8.88859 9.92565C8.83059 9.86177 8.78574 9.7871 8.7566 9.70589C8.72745 9.62468 8.71459 9.53852 8.71875 9.45234C8.72391 9.33844 8.80313 8.31234 9.87984 7.44609C10.4381 6.99703 11.1483 6.76359 11.9892 6.75328C12.5845 6.74625 13.1437 6.84703 13.523 7.02609C14.6578 7.56281 15.2812 8.45766 15.2812 9.54234C15.2812 11.1281 14.2214 11.8402 13.2863 12.4688Z"
                                    fill="#CECECE"
                                />
                            </svg>
                        </div>
                        <div class="second-column column">-</div>
                        <div class="third-column column">-</div>
                        <div class="fourth-column column">
                            <img loading="lazy" decoding="async" src="@/assets/checkmark-2.svg" alt="" />
                        </div>
                        <div class="fifth-column column">
                            <img loading="lazy" decoding="async" src="@/assets/checkmark-2.svg" alt="" />
                        </div>
                    </div>
                </div>
                <div class="tables table-four">
                    <div class="header-row">{{ $t("Anti-Cheat Measures") }}</div>
                    <div class="row">
                        <div class="first-column column">
                            {{ $t("Time Limits on Tests") }}
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
                                <path
                                    d="M12 3C7.03125 3 3 7.03125 3 12C3 16.9688 7.03125 21 12 21C16.9688 21 21 16.9688 21 12C21 7.03125 16.9688 3 12 3ZM11.7188 17.25C11.5333 17.25 11.3521 17.195 11.1979 17.092C11.0437 16.989 10.9236 16.8426 10.8526 16.6713C10.7817 16.5 10.7631 16.3115 10.7993 16.1296C10.8354 15.9477 10.9247 15.7807 11.0558 15.6496C11.1869 15.5185 11.354 15.4292 11.5359 15.393C11.7177 15.3568 11.9062 15.3754 12.0775 15.4464C12.2488 15.5173 12.3952 15.6375 12.4983 15.7917C12.6013 15.9458 12.6562 16.1271 12.6562 16.3125C12.6562 16.5611 12.5575 16.7996 12.3817 16.9754C12.2058 17.1512 11.9674 17.25 11.7188 17.25ZM13.2863 12.4688C12.5264 12.9788 12.4219 13.4461 12.4219 13.875C12.4219 14.049 12.3527 14.216 12.2297 14.339C12.1066 14.4621 11.9397 14.5312 11.7656 14.5312C11.5916 14.5312 11.4247 14.4621 11.3016 14.339C11.1785 14.216 11.1094 14.049 11.1094 13.875C11.1094 12.848 11.5819 12.0314 12.5541 11.3784C13.4578 10.7719 13.9688 10.3875 13.9688 9.54234C13.9688 8.96766 13.6406 8.53125 12.9614 8.20828C12.8016 8.13234 12.4458 8.05828 12.008 8.06344C11.4586 8.07047 11.032 8.20172 10.7034 8.46609C10.0837 8.96484 10.0312 9.50766 10.0312 9.51562C10.0271 9.60181 10.006 9.68632 9.96919 9.76435C9.93237 9.84238 9.88054 9.9124 9.81667 9.9704C9.75279 10.0284 9.67811 10.0732 9.5969 10.1024C9.51569 10.1315 9.42954 10.1444 9.34336 10.1402C9.25718 10.1361 9.17266 10.115 9.09463 10.0782C9.0166 10.0414 8.94659 9.98953 8.88859 9.92565C8.83059 9.86177 8.78574 9.7871 8.7566 9.70589C8.72745 9.62468 8.71459 9.53852 8.71875 9.45234C8.72391 9.33844 8.80313 8.31234 9.87984 7.44609C10.4381 6.99703 11.1483 6.76359 11.9892 6.75328C12.5845 6.74625 13.1437 6.84703 13.523 7.02609C14.6578 7.56281 15.2812 8.45766 15.2812 9.54234C15.2812 11.1281 14.2214 11.8402 13.2863 12.4688Z"
                                    fill="#CECECE"
                                />
                            </svg>
                        </div>
                        <div class="second-column column">-</div>

                        <div class="third-column column">
                            <img loading="lazy" decoding="async" src="@/assets/checkmark-2.svg" alt="" />
                        </div>
                        <div class="fourth-column column">
                            <img loading="lazy" decoding="async" src="@/assets/checkmark-2.svg" alt="" />
                        </div>
                        <div class="fifth-column column">
                            <img loading="lazy" decoding="async" src="@/assets/checkmark-2.svg" alt="" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="first-column column">
                            {{ $t("IP Address Logging") }}
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
                                <path
                                    d="M12 3C7.03125 3 3 7.03125 3 12C3 16.9688 7.03125 21 12 21C16.9688 21 21 16.9688 21 12C21 7.03125 16.9688 3 12 3ZM11.7188 17.25C11.5333 17.25 11.3521 17.195 11.1979 17.092C11.0437 16.989 10.9236 16.8426 10.8526 16.6713C10.7817 16.5 10.7631 16.3115 10.7993 16.1296C10.8354 15.9477 10.9247 15.7807 11.0558 15.6496C11.1869 15.5185 11.354 15.4292 11.5359 15.393C11.7177 15.3568 11.9062 15.3754 12.0775 15.4464C12.2488 15.5173 12.3952 15.6375 12.4983 15.7917C12.6013 15.9458 12.6562 16.1271 12.6562 16.3125C12.6562 16.5611 12.5575 16.7996 12.3817 16.9754C12.2058 17.1512 11.9674 17.25 11.7188 17.25ZM13.2863 12.4688C12.5264 12.9788 12.4219 13.4461 12.4219 13.875C12.4219 14.049 12.3527 14.216 12.2297 14.339C12.1066 14.4621 11.9397 14.5312 11.7656 14.5312C11.5916 14.5312 11.4247 14.4621 11.3016 14.339C11.1785 14.216 11.1094 14.049 11.1094 13.875C11.1094 12.848 11.5819 12.0314 12.5541 11.3784C13.4578 10.7719 13.9688 10.3875 13.9688 9.54234C13.9688 8.96766 13.6406 8.53125 12.9614 8.20828C12.8016 8.13234 12.4458 8.05828 12.008 8.06344C11.4586 8.07047 11.032 8.20172 10.7034 8.46609C10.0837 8.96484 10.0312 9.50766 10.0312 9.51562C10.0271 9.60181 10.006 9.68632 9.96919 9.76435C9.93237 9.84238 9.88054 9.9124 9.81667 9.9704C9.75279 10.0284 9.67811 10.0732 9.5969 10.1024C9.51569 10.1315 9.42954 10.1444 9.34336 10.1402C9.25718 10.1361 9.17266 10.115 9.09463 10.0782C9.0166 10.0414 8.94659 9.98953 8.88859 9.92565C8.83059 9.86177 8.78574 9.7871 8.7566 9.70589C8.72745 9.62468 8.71459 9.53852 8.71875 9.45234C8.72391 9.33844 8.80313 8.31234 9.87984 7.44609C10.4381 6.99703 11.1483 6.76359 11.9892 6.75328C12.5845 6.74625 13.1437 6.84703 13.523 7.02609C14.6578 7.56281 15.2812 8.45766 15.2812 9.54234C15.2812 11.1281 14.2214 11.8402 13.2863 12.4688Z"
                                    fill="#CECECE"
                                />
                            </svg>
                        </div>
                        <div class="second-column column">-</div>

                        <div class="third-column column">
                            <img loading="lazy" decoding="async" src="@/assets/checkmark-2.svg" alt="" />
                        </div>
                        <div class="fourth-column column">
                            <img loading="lazy" decoding="async" src="@/assets/checkmark-2.svg" alt="" />
                        </div>
                        <div class="fifth-column column">
                            <img loading="lazy" decoding="async" src="@/assets/checkmark-2.svg" alt="" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="first-column column">
                            {{ $t("Copy-Paste Disabled") }}
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
                                <path
                                    d="M12 3C7.03125 3 3 7.03125 3 12C3 16.9688 7.03125 21 12 21C16.9688 21 21 16.9688 21 12C21 7.03125 16.9688 3 12 3ZM11.7188 17.25C11.5333 17.25 11.3521 17.195 11.1979 17.092C11.0437 16.989 10.9236 16.8426 10.8526 16.6713C10.7817 16.5 10.7631 16.3115 10.7993 16.1296C10.8354 15.9477 10.9247 15.7807 11.0558 15.6496C11.1869 15.5185 11.354 15.4292 11.5359 15.393C11.7177 15.3568 11.9062 15.3754 12.0775 15.4464C12.2488 15.5173 12.3952 15.6375 12.4983 15.7917C12.6013 15.9458 12.6562 16.1271 12.6562 16.3125C12.6562 16.5611 12.5575 16.7996 12.3817 16.9754C12.2058 17.1512 11.9674 17.25 11.7188 17.25ZM13.2863 12.4688C12.5264 12.9788 12.4219 13.4461 12.4219 13.875C12.4219 14.049 12.3527 14.216 12.2297 14.339C12.1066 14.4621 11.9397 14.5312 11.7656 14.5312C11.5916 14.5312 11.4247 14.4621 11.3016 14.339C11.1785 14.216 11.1094 14.049 11.1094 13.875C11.1094 12.848 11.5819 12.0314 12.5541 11.3784C13.4578 10.7719 13.9688 10.3875 13.9688 9.54234C13.9688 8.96766 13.6406 8.53125 12.9614 8.20828C12.8016 8.13234 12.4458 8.05828 12.008 8.06344C11.4586 8.07047 11.032 8.20172 10.7034 8.46609C10.0837 8.96484 10.0312 9.50766 10.0312 9.51562C10.0271 9.60181 10.006 9.68632 9.96919 9.76435C9.93237 9.84238 9.88054 9.9124 9.81667 9.9704C9.75279 10.0284 9.67811 10.0732 9.5969 10.1024C9.51569 10.1315 9.42954 10.1444 9.34336 10.1402C9.25718 10.1361 9.17266 10.115 9.09463 10.0782C9.0166 10.0414 8.94659 9.98953 8.88859 9.92565C8.83059 9.86177 8.78574 9.7871 8.7566 9.70589C8.72745 9.62468 8.71459 9.53852 8.71875 9.45234C8.72391 9.33844 8.80313 8.31234 9.87984 7.44609C10.4381 6.99703 11.1483 6.76359 11.9892 6.75328C12.5845 6.74625 13.1437 6.84703 13.523 7.02609C14.6578 7.56281 15.2812 8.45766 15.2812 9.54234C15.2812 11.1281 14.2214 11.8402 13.2863 12.4688Z"
                                    fill="#CECECE"
                                />
                            </svg>
                        </div>
                        <div class="second-column column">-</div>

                        <div class="third-column column">
                            <img loading="lazy" decoding="async" src="@/assets/checkmark-2.svg" alt="" />
                        </div>
                        <div class="fourth-column column">
                            <img loading="lazy" decoding="async" src="@/assets/checkmark-2.svg" alt="" />
                        </div>
                        <div class="fifth-column column">
                            <img loading="lazy" decoding="async" src="@/assets/checkmark-2.svg" alt="" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="first-column column">
                            {{ $t("Webcam Snapshots") }}
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
                                <path
                                    d="M12 3C7.03125 3 3 7.03125 3 12C3 16.9688 7.03125 21 12 21C16.9688 21 21 16.9688 21 12C21 7.03125 16.9688 3 12 3ZM11.7188 17.25C11.5333 17.25 11.3521 17.195 11.1979 17.092C11.0437 16.989 10.9236 16.8426 10.8526 16.6713C10.7817 16.5 10.7631 16.3115 10.7993 16.1296C10.8354 15.9477 10.9247 15.7807 11.0558 15.6496C11.1869 15.5185 11.354 15.4292 11.5359 15.393C11.7177 15.3568 11.9062 15.3754 12.0775 15.4464C12.2488 15.5173 12.3952 15.6375 12.4983 15.7917C12.6013 15.9458 12.6562 16.1271 12.6562 16.3125C12.6562 16.5611 12.5575 16.7996 12.3817 16.9754C12.2058 17.1512 11.9674 17.25 11.7188 17.25ZM13.2863 12.4688C12.5264 12.9788 12.4219 13.4461 12.4219 13.875C12.4219 14.049 12.3527 14.216 12.2297 14.339C12.1066 14.4621 11.9397 14.5312 11.7656 14.5312C11.5916 14.5312 11.4247 14.4621 11.3016 14.339C11.1785 14.216 11.1094 14.049 11.1094 13.875C11.1094 12.848 11.5819 12.0314 12.5541 11.3784C13.4578 10.7719 13.9688 10.3875 13.9688 9.54234C13.9688 8.96766 13.6406 8.53125 12.9614 8.20828C12.8016 8.13234 12.4458 8.05828 12.008 8.06344C11.4586 8.07047 11.032 8.20172 10.7034 8.46609C10.0837 8.96484 10.0312 9.50766 10.0312 9.51562C10.0271 9.60181 10.006 9.68632 9.96919 9.76435C9.93237 9.84238 9.88054 9.9124 9.81667 9.9704C9.75279 10.0284 9.67811 10.0732 9.5969 10.1024C9.51569 10.1315 9.42954 10.1444 9.34336 10.1402C9.25718 10.1361 9.17266 10.115 9.09463 10.0782C9.0166 10.0414 8.94659 9.98953 8.88859 9.92565C8.83059 9.86177 8.78574 9.7871 8.7566 9.70589C8.72745 9.62468 8.71459 9.53852 8.71875 9.45234C8.72391 9.33844 8.80313 8.31234 9.87984 7.44609C10.4381 6.99703 11.1483 6.76359 11.9892 6.75328C12.5845 6.74625 13.1437 6.84703 13.523 7.02609C14.6578 7.56281 15.2812 8.45766 15.2812 9.54234C15.2812 11.1281 14.2214 11.8402 13.2863 12.4688Z"
                                    fill="#CECECE"
                                />
                            </svg>
                        </div>
                        <div class="second-column column">-</div>

                        <div class="third-column column">
                            <img loading="lazy" decoding="async" src="@/assets/checkmark-2.svg" alt="" />
                        </div>
                        <div class="fourth-column column">
                            <img loading="lazy" decoding="async" src="@/assets/checkmark-2.svg" alt="" />
                        </div>
                        <div class="fifth-column column">
                            <img loading="lazy" decoding="async" src="@/assets/checkmark-2.svg" alt="" />
                        </div>
                    </div>
                </div>
                <div class="tables table-five">
                    <div class="header-row">{{ $t("Quality Control") }}</div>
                    <div class="row">
                        <div class="first-column column">
                            {{ $t("GDPR Compliance") }}
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
                                <path
                                    d="M12 3C7.03125 3 3 7.03125 3 12C3 16.9688 7.03125 21 12 21C16.9688 21 21 16.9688 21 12C21 7.03125 16.9688 3 12 3ZM11.7188 17.25C11.5333 17.25 11.3521 17.195 11.1979 17.092C11.0437 16.989 10.9236 16.8426 10.8526 16.6713C10.7817 16.5 10.7631 16.3115 10.7993 16.1296C10.8354 15.9477 10.9247 15.7807 11.0558 15.6496C11.1869 15.5185 11.354 15.4292 11.5359 15.393C11.7177 15.3568 11.9062 15.3754 12.0775 15.4464C12.2488 15.5173 12.3952 15.6375 12.4983 15.7917C12.6013 15.9458 12.6562 16.1271 12.6562 16.3125C12.6562 16.5611 12.5575 16.7996 12.3817 16.9754C12.2058 17.1512 11.9674 17.25 11.7188 17.25ZM13.2863 12.4688C12.5264 12.9788 12.4219 13.4461 12.4219 13.875C12.4219 14.049 12.3527 14.216 12.2297 14.339C12.1066 14.4621 11.9397 14.5312 11.7656 14.5312C11.5916 14.5312 11.4247 14.4621 11.3016 14.339C11.1785 14.216 11.1094 14.049 11.1094 13.875C11.1094 12.848 11.5819 12.0314 12.5541 11.3784C13.4578 10.7719 13.9688 10.3875 13.9688 9.54234C13.9688 8.96766 13.6406 8.53125 12.9614 8.20828C12.8016 8.13234 12.4458 8.05828 12.008 8.06344C11.4586 8.07047 11.032 8.20172 10.7034 8.46609C10.0837 8.96484 10.0312 9.50766 10.0312 9.51562C10.0271 9.60181 10.006 9.68632 9.96919 9.76435C9.93237 9.84238 9.88054 9.9124 9.81667 9.9704C9.75279 10.0284 9.67811 10.0732 9.5969 10.1024C9.51569 10.1315 9.42954 10.1444 9.34336 10.1402C9.25718 10.1361 9.17266 10.115 9.09463 10.0782C9.0166 10.0414 8.94659 9.98953 8.88859 9.92565C8.83059 9.86177 8.78574 9.7871 8.7566 9.70589C8.72745 9.62468 8.71459 9.53852 8.71875 9.45234C8.72391 9.33844 8.80313 8.31234 9.87984 7.44609C10.4381 6.99703 11.1483 6.76359 11.9892 6.75328C12.5845 6.74625 13.1437 6.84703 13.523 7.02609C14.6578 7.56281 15.2812 8.45766 15.2812 9.54234C15.2812 11.1281 14.2214 11.8402 13.2863 12.4688Z"
                                    fill="#CECECE"
                                />
                            </svg>
                        </div>

                        <div class="second-column column">
                            <img loading="lazy" decoding="async" src="@/assets/checkmark-2.svg" alt="" />
                        </div>
                        <div class="third-column column">
                            <img loading="lazy" decoding="async" src="@/assets/checkmark-2.svg" alt="" />
                        </div>
                        <div class="fourth-column column">
                            <img loading="lazy" decoding="async" src="@/assets/checkmark-2.svg" alt="" />
                        </div>
                        <div class="fifth-column column">
                            <img loading="lazy" decoding="async" src="@/assets/checkmark-2.svg" alt="" />
                        </div>
                    </div>
                    <div class="row text-row">
                        <div class="first-column column">
                            {{ $t("Webcam Snapshots") }}
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
                                <path
                                    d="M12 3C7.03125 3 3 7.03125 3 12C3 16.9688 7.03125 21 12 21C16.9688 21 21 16.9688 21 12C21 7.03125 16.9688 3 12 3ZM11.7188 17.25C11.5333 17.25 11.3521 17.195 11.1979 17.092C11.0437 16.989 10.9236 16.8426 10.8526 16.6713C10.7817 16.5 10.7631 16.3115 10.7993 16.1296C10.8354 15.9477 10.9247 15.7807 11.0558 15.6496C11.1869 15.5185 11.354 15.4292 11.5359 15.393C11.7177 15.3568 11.9062 15.3754 12.0775 15.4464C12.2488 15.5173 12.3952 15.6375 12.4983 15.7917C12.6013 15.9458 12.6562 16.1271 12.6562 16.3125C12.6562 16.5611 12.5575 16.7996 12.3817 16.9754C12.2058 17.1512 11.9674 17.25 11.7188 17.25ZM13.2863 12.4688C12.5264 12.9788 12.4219 13.4461 12.4219 13.875C12.4219 14.049 12.3527 14.216 12.2297 14.339C12.1066 14.4621 11.9397 14.5312 11.7656 14.5312C11.5916 14.5312 11.4247 14.4621 11.3016 14.339C11.1785 14.216 11.1094 14.049 11.1094 13.875C11.1094 12.848 11.5819 12.0314 12.5541 11.3784C13.4578 10.7719 13.9688 10.3875 13.9688 9.54234C13.9688 8.96766 13.6406 8.53125 12.9614 8.20828C12.8016 8.13234 12.4458 8.05828 12.008 8.06344C11.4586 8.07047 11.032 8.20172 10.7034 8.46609C10.0837 8.96484 10.0312 9.50766 10.0312 9.51562C10.0271 9.60181 10.006 9.68632 9.96919 9.76435C9.93237 9.84238 9.88054 9.9124 9.81667 9.9704C9.75279 10.0284 9.67811 10.0732 9.5969 10.1024C9.51569 10.1315 9.42954 10.1444 9.34336 10.1402C9.25718 10.1361 9.17266 10.115 9.09463 10.0782C9.0166 10.0414 8.94659 9.98953 8.88859 9.92565C8.83059 9.86177 8.78574 9.7871 8.7566 9.70589C8.72745 9.62468 8.71459 9.53852 8.71875 9.45234C8.72391 9.33844 8.80313 8.31234 9.87984 7.44609C10.4381 6.99703 11.1483 6.76359 11.9892 6.75328C12.5845 6.74625 13.1437 6.84703 13.523 7.02609C14.6578 7.56281 15.2812 8.45766 15.2812 9.54234C15.2812 11.1281 14.2214 11.8402 13.2863 12.4688Z"
                                    fill="#CECECE"
                                />
                            </svg>
                        </div>
                        <div class="second-column column">
                            {{ $t("15 step") }}
                        </div>
                    </div>
                    <div class="row">
                        <div class="first-column column">
                            {{ $t("Copy-Paste Disabled") }}
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
                                <path
                                    d="M12 3C7.03125 3 3 7.03125 3 12C3 16.9688 7.03125 21 12 21C16.9688 21 21 16.9688 21 12C21 7.03125 16.9688 3 12 3ZM11.7188 17.25C11.5333 17.25 11.3521 17.195 11.1979 17.092C11.0437 16.989 10.9236 16.8426 10.8526 16.6713C10.7817 16.5 10.7631 16.3115 10.7993 16.1296C10.8354 15.9477 10.9247 15.7807 11.0558 15.6496C11.1869 15.5185 11.354 15.4292 11.5359 15.393C11.7177 15.3568 11.9062 15.3754 12.0775 15.4464C12.2488 15.5173 12.3952 15.6375 12.4983 15.7917C12.6013 15.9458 12.6562 16.1271 12.6562 16.3125C12.6562 16.5611 12.5575 16.7996 12.3817 16.9754C12.2058 17.1512 11.9674 17.25 11.7188 17.25ZM13.2863 12.4688C12.5264 12.9788 12.4219 13.4461 12.4219 13.875C12.4219 14.049 12.3527 14.216 12.2297 14.339C12.1066 14.4621 11.9397 14.5312 11.7656 14.5312C11.5916 14.5312 11.4247 14.4621 11.3016 14.339C11.1785 14.216 11.1094 14.049 11.1094 13.875C11.1094 12.848 11.5819 12.0314 12.5541 11.3784C13.4578 10.7719 13.9688 10.3875 13.9688 9.54234C13.9688 8.96766 13.6406 8.53125 12.9614 8.20828C12.8016 8.13234 12.4458 8.05828 12.008 8.06344C11.4586 8.07047 11.032 8.20172 10.7034 8.46609C10.0837 8.96484 10.0312 9.50766 10.0312 9.51562C10.0271 9.60181 10.006 9.68632 9.96919 9.76435C9.93237 9.84238 9.88054 9.9124 9.81667 9.9704C9.75279 10.0284 9.67811 10.0732 9.5969 10.1024C9.51569 10.1315 9.42954 10.1444 9.34336 10.1402C9.25718 10.1361 9.17266 10.115 9.09463 10.0782C9.0166 10.0414 8.94659 9.98953 8.88859 9.92565C8.83059 9.86177 8.78574 9.7871 8.7566 9.70589C8.72745 9.62468 8.71459 9.53852 8.71875 9.45234C8.72391 9.33844 8.80313 8.31234 9.87984 7.44609C10.4381 6.99703 11.1483 6.76359 11.9892 6.75328C12.5845 6.74625 13.1437 6.84703 13.523 7.02609C14.6578 7.56281 15.2812 8.45766 15.2812 9.54234C15.2812 11.1281 14.2214 11.8402 13.2863 12.4688Z"
                                    fill="#CECECE"
                                />
                            </svg>
                        </div>

                        <div class="second-column column">
                            <img loading="lazy" decoding="async" src="@/assets/checkmark-2.svg" alt="" />
                        </div>
                        <div class="third-column column">
                            <img loading="lazy" decoding="async" src="@/assets/checkmark-2.svg" alt="" />
                        </div>
                        <div class="fourth-column column">
                            <img loading="lazy" decoding="async" src="@/assets/checkmark-2.svg" alt="" />
                        </div>
                        <div class="fifth-column column">
                            <img loading="lazy" decoding="async" src="@/assets/checkmark-2.svg" alt="" />
                        </div>
                    </div>
                    <div class="row text-row">
                        <div class="first-column column">
                            {{ $t("Webcam Snapshots") }}
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
                                <path
                                    d="M12 3C7.03125 3 3 7.03125 3 12C3 16.9688 7.03125 21 12 21C16.9688 21 21 16.9688 21 12C21 7.03125 16.9688 3 12 3ZM11.7188 17.25C11.5333 17.25 11.3521 17.195 11.1979 17.092C11.0437 16.989 10.9236 16.8426 10.8526 16.6713C10.7817 16.5 10.7631 16.3115 10.7993 16.1296C10.8354 15.9477 10.9247 15.7807 11.0558 15.6496C11.1869 15.5185 11.354 15.4292 11.5359 15.393C11.7177 15.3568 11.9062 15.3754 12.0775 15.4464C12.2488 15.5173 12.3952 15.6375 12.4983 15.7917C12.6013 15.9458 12.6562 16.1271 12.6562 16.3125C12.6562 16.5611 12.5575 16.7996 12.3817 16.9754C12.2058 17.1512 11.9674 17.25 11.7188 17.25ZM13.2863 12.4688C12.5264 12.9788 12.4219 13.4461 12.4219 13.875C12.4219 14.049 12.3527 14.216 12.2297 14.339C12.1066 14.4621 11.9397 14.5312 11.7656 14.5312C11.5916 14.5312 11.4247 14.4621 11.3016 14.339C11.1785 14.216 11.1094 14.049 11.1094 13.875C11.1094 12.848 11.5819 12.0314 12.5541 11.3784C13.4578 10.7719 13.9688 10.3875 13.9688 9.54234C13.9688 8.96766 13.6406 8.53125 12.9614 8.20828C12.8016 8.13234 12.4458 8.05828 12.008 8.06344C11.4586 8.07047 11.032 8.20172 10.7034 8.46609C10.0837 8.96484 10.0312 9.50766 10.0312 9.51562C10.0271 9.60181 10.006 9.68632 9.96919 9.76435C9.93237 9.84238 9.88054 9.9124 9.81667 9.9704C9.75279 10.0284 9.67811 10.0732 9.5969 10.1024C9.51569 10.1315 9.42954 10.1444 9.34336 10.1402C9.25718 10.1361 9.17266 10.115 9.09463 10.0782C9.0166 10.0414 8.94659 9.98953 8.88859 9.92565C8.83059 9.86177 8.78574 9.7871 8.7566 9.70589C8.72745 9.62468 8.71459 9.53852 8.71875 9.45234C8.72391 9.33844 8.80313 8.31234 9.87984 7.44609C10.4381 6.99703 11.1483 6.76359 11.9892 6.75328C12.5845 6.74625 13.1437 6.84703 13.523 7.02609C14.6578 7.56281 15.2812 8.45766 15.2812 9.54234C15.2812 11.1281 14.2214 11.8402 13.2863 12.4688Z"
                                    fill="#CECECE"
                                />
                            </svg>
                        </div>
                        <div class="second-column column">
                            {{ $t("To maintain test integrity") }}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import PlanningContact from "@/components/PlanningContact";

export default {
    name: "PlanningView",
    components: {
        PlanningContact,
    },
    data() {
        return {
            isShown: false,
            Plan: "month",
            showEmail: false,
            selectedPlan: "",
        };
    },
    methods: {
        showMore() {
            this.isShown = true;
        },
        SelectPlan(plan) {
            this.Plan = plan;
        },
        toggleEmailWindow(selectedPlan) {
            this.selectedPlan = selectedPlan;
            this.showEmail = !this.showEmail;
        },
        navigateToRegister(plan) {
            this.$router.push({
                path: "/register",
                query: { plan: plan },
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.feature-table {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-top: 7%;
    width: 75%;
    @media (max-width: 767.98px) {
        display: none;
    }
}

.pricing-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10%;
    margin-bottom: 10%;
    @media (max-width: 767.98px) {
        padding-top: 4rem;
    }
}

.pricing-panel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    width: 80%;
}

.header-two {
    text-align: start;
    width: 75%;
    display: flex;
    align-items: flex-start;
    margin-bottom: 40px;
}

.header-two h4 {
    text-align: start;
}

.header {
    text-align: center;
}

.header-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.header h1 {
    color: var(--sementics-color-fg-default, #18181b);
    /* sementic.type/title/xl */
    font-family: Roboto;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 20%;
    @media (max-width: 767.98px) {
        line-height: 40px;
    }
    /* 36.8px */
}

.header h4 {
    color: var(--sementics-color-fg-default, #18181b);
    /* sementic.type/body/xl */
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    margin-top: 20px;

    /* 22.5px */
}

.feature-table .saving-container {
    width: 85%;
    display: flex;
    align-items: center;
    align-items: flex-end;
    margin-bottom: 20px;
}

.pricing-panel .saving-container {
    width: 85%;
    display: flex;
    align-items: center;
    align-items: flex-end;
    margin-bottom: 20px;
}

.feature-table .saving-container {
    width: 85%;
    display: flex;
    align-items: center;
    align-items: flex-end;
    margin-bottom: 20px;
}

.pricing-card {
    button {
        border: none;
        cursor: pointer;
        transition: 0.3s;
        color: white;
    }
}

button:hover {
    opacity: 0.8;
}

.time-container {
    display: inline-flex;
    padding: 4px;
    align-items: flex-start;
    border-radius: 100px;
    border: 1px solid var(--sementics-color-border-muted, #e4e4e7);
    background: var(--sementics-color-primary-on-default, #fff);
    margin-left: 10px;
}

.month {
    display: flex;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 100px;
    background: transparent;
    font-family: Inter;
    font-size: 8px;
    font-style: normal;
    font-weight: 600;
    line-height: 115%;
    position: relative;
    z-index: 5;
    width: 50%;
    /* 16.1px */
    &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 100px;
        background: #2196f3;
        left: 0;
        top: 0;
        z-index: -1;
        transition: all 500ms ease-out;
    }
}

.year {
    display: flex;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 100px;
    background: transparent;
    font-family: Inter;
    font-size: 8px;
    font-style: normal;
    font-weight: 600;
    line-height: 115%;
    z-index: 5;
    width: 50%;
    /* 16.1px */
}

.save-span {
    color: #2196f3;
    /* sementic.type/label/s */
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 125%;
}

.save-span span {
    color: #2196f3;
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%;
    /* 15px */
}

.learn-more {
    margin-top: 3%;
    @media (max-width: 767.98px) {
        display: none;
    }
}

.learn-more h1 {
    color: #000;
    /* sementic.type/title/xl */
    font-family: Roboto;
    font-size: 27px;
    font-style: normal;
    font-weight: 400;
    line-height: 20%;
    text-align: start;
    cursor: pointer;
    @media (max-width: 767.98px) {
        line-height: 40px;
    }

    &:hover span {
        text-decoration: underline;
        color: #2196f3;
    }
}

.learn-more h1 span {
    color: #000;
    /* sementic.type/title/xl */
    font-family: Roboto;
    font-size: 27px;
    font-style: normal;
    font-weight: 400;
    line-height: 20%;
    text-align: start;
}

.price-value {
    color: var(--sementics-color-fg-default, #18181b);

    /* sementic.type/body/s */
    font-family: Roboto;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    /* 13.8px */
}

.price-value span {
    color: var(--sementics-color-fg-default, #18181b);

    font-family: Roboto;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    /* 36.8px */
}

.pricing-cards-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    width: 100%;
    @media (max-width: 767.98px) {
        flex-direction: column;
        .pricing-card {
            width: 90%;
            margin: 0 auto;
        }
    }
}

.pricing-cards-wrapper button {
    background: #2196f3;
}

.blank-container {
    width: 25%;
}

.small-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    min-width: 75%;
}

.small-wrapper button {
    background: #2196f3;
}

.cards-features {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.pricing-card {
    display: flex;
    padding: 24px 16px 16px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid var(--sementics-color-border-muted, #e4e4e7);
    background: var(--sementics-color-bg-surface, #fff);
    width: 20%;
}

.small-cards {
    display: flex;
    padding: 24px 16px 16px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid var(--sementics-color-border-muted, #e4e4e7);
    background: var(--sementics-color-bg-surface, #fff);
    width: 25%;
    height: auto;
}

.pro {
    border: solid 2px #2196f3;
    position: relative;
    border-radius: 8px;
}

.popular-span svg {
    margin-left: 5px;
}

.popular-span {
    position: absolute;
    width: 100%;
    background: #2196f3;
    border: solid 2px #2196f3;
    border-radius: 5px 5px 0px 0px;

    color: #fff;
    top: 0px;
    left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
}

.pricing-card-button {
    display: flex;
    padding: 12px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 4px;
    background: var(--sementics-color-primary-default, #2563eb);
}

.button-enterprise {
    background: #fff !important;
    color: #2196f3;
    border: 1px solid #2196f3;
}

.pricing-header {
    display: flex;
    padding: 0px 8px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
}

.pricing-type {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
}

.pricing-type .h2 {
    color: var(--sementics-color-fg-default, #18181b);

    /* sementic.type/title/l */
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    /* 23px */
}

.pricing-type .h5 {
    color: var(--sementics-color-fg-default, #18181b);

    /* sementic.type/body/m */
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    text-align: start;

    /* 17.5px */
}

.pricing-value {
    display: flex;
    align-items: flex-end;
}

.pricing-features {
    display: flex;
    padding: 0px 16px 16px 8px;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
    height: auto;
    width: 100%;
}

.pricing-features .h5 {
    color: var(--sementics-color-fg-default, #18181b);

    /* sementic.type/label/l */
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 115%;
    text-align: start;
    width: 100%;
    /* 18.4px */
}

.infos-list {
    list-style-type: none;
    align-items: flex-start;
    margin-left: 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    width: 100%;
}

.infos-list div {
    color: var(--sementics-color-fg-default, #18181b);

    /* sementic.type/body/m */
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: start;
    width: 100%;
    /* 17.5px */
}

svg {
    margin-right: 10px;
}

.table-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 98%;
    margin-top: 30px;
}

.tables {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
}

.row {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 15px 10px;
    color: var(--sementics-color-fg-default, #18181b);

    /* sementic.type/body/l */
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    border-bottom: 1px solid var(--sementics-color-border-disabled, #d4d4d8);
}

.header-row {
    border-radius: 8px;
    background: var(--sementics-color-bg-subtle, #e4e4e7);
    width: 100%;
    padding: 12px 10px;
    color: var(--sementics-color-fg-default, #18181b);

    /* sementic.type/title/l */
    font-family: Roboto;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    text-align: start;
    /* 23px */
}

.column {
    width: 20%;
    text-align: center;
}

.column img {
    margin-left: 50%;
    transform: translateX(-50%);
}

.first-column {
    text-align: start;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
}

.second-column {
    color: #71717a;
}

.third-column {
    color: #71717a;
}

.fourth-column {
    color: #71717a;
}

.fifth-column {
    color: #2563eb;
}

.table-container img {
    background: #2563eb;
    border-radius: 50%;
    padding: 2px;
}

.text-row .first-column {
    width: 20%;
}

.text-row .second-column {
    width: 80%;
}

@media (max-width: 1322px) {
    .pricing-header {
        height: 114px;
    }

    .pricing-type {
        height: 49px;
    }
}

@media (max-width: 1253px) {
    .small-cards {
        gap: 10px;
        display: flex;
        padding: 24px 16px 16px 16px;
        flex-direction: column;
        align-items: flex-start;

        align-self: stretch;
        border-radius: 8px;
        border: 1px solid var(--sementics-color-border-muted, #e4e4e7);
        background: var(--sementics-color-bg-surface, #fff);
        width: 30%;
        height: auto;
    }

    .blank-container {
        display: none;
    }

    .small-wrapper {
        width: 100%;
    }
}
</style>
